import React, { useState } from 'react';
import { getData, postData } from '../requests';
import { Button ,Typography, TextField} from '@mui/material';


function InputBox() {
const [input, setInput] = useState()
const [data, setData ] = useState()


const handleInputChange = (event) => {
  setInput(event.target.value);
}


    const handleButtonClick = async () => {
      try {

        const response = await postData(input);
        console.log('Response from API:', response);
        setData(response)

      } catch (error) {
        console.error('Error posting data to API:', error);
        setData(error.message)
      }
    }
      

      const handleButtonClickGet = async () => {
        try {
  
          const response = await getData(`football/fixtures?${input}`);
          console.log('Response from API:', response);
         setData(response)
  
        } catch (error) {
          console.error('Error posting data to API:', error);
          setData(error.message)
        }
      }
    
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
       <TextField
        label="Data"
        variant="outlined"
        value={input}
        onChange={handleInputChange}
      />
      <Button variant="contained" onClick={handleButtonClick}>
      Submit
    </Button>
    <Button variant="contained" onClick={handleButtonClickGet}>
      Submit GET
    </Button>
    {data && <Typography> {data} </Typography>}
    </div>
  );
}
    

export default InputBox