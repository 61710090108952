// App.js
import React from 'react';
import './App.css';
import InputBox from './components/InputBox';

function App() {
  return (
    <div className="App">
      <InputBox />
    </div>
  );
}

export default App;