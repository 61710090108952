import axios from 'axios';

const BASE_URL = 'https://api.fixturefy.com/v1' 

async function getData(endpoint) {
    try {
      const response = await axios.get(`${BASE_URL}/${endpoint}`);
      console.log(response)
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  

  async function postData( bodyData) {

    try {
      const response = await axios.post(`${BASE_URL}/football/fixtures`, bodyData);
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  }
  


export { getData, postData };